@import (reference) '~theme/globals/site.variables';
@import (reference) '../../DatacenterTopology/drawables/railsPalette.less';

.neighbors-view {
  .rails {
    .rail {
      .link {
        // stroke-width: 3px;
        fill: none;
      }
  
      .link-background {
        stroke-width: 8px;
        stroke: transparent;
      }
  
      &.outline {
        .link {
          stroke-width: 10px;
          stroke-dasharray: none;
          opacity: 0.2;
        }

        &.hovered .link {
          opacity: 0.8;
        }

        each(@railsPalette, {
          @zeroIndex: @index - 1;
          &.rail@{zeroIndex} .link {
            stroke: @value !important;
          }
        });
      }
    }
  }
}

div.link-tooltip {
  display: flex;
  flex-direction: row;

  & > div {
    margin: 0;

    &:nth-child(n + 2) {
      margin-left: 10px;
    }
  }
}
