@import '../variables.less';

div.re-linker {
  min-width: 360px;

  & .new-link.title {
    display: flex;

    & > i {
      flex: 0 0 10px;
    }
  }

  h5 {
    margin: 0 0 4px 0;
  }

  h6 {
    margin: 0 0 8px 0;
    font-size: 0.8rem;
  }

  .re-links-form {
    display: flex;

    &.green {
      background-color: lighten(@green, 45%);
      padding: 10px !important;
      margin: 0 -5px;
    }

    & > *:nth-child(n + 2) {
      margin-left: 10px !important;
    }

    .field {
      .required-field();
      flex: 1 1;
      margin-left: 8px;

      label {
        white-space: nowrap;
      }

      &.checkboxes {
        & > label {
          display: block;
          margin-bottom: 6px;
        }

        .checkbox:nth-child(n + 3) {
          margin-left: 10px;
        }

        white-space: nowrap;
      }
    }

    div.re-ports {
      flex: 0 0 30px;
      margin: 2px 0 0 0;
      padding: 2px;
    }

    .button:not(.re-port) {
      margin: 18px 0 0 10px;
      height: 30px;
    }
  }
}

.ui.error.message.re-linker-errors {
  .popup-errors();
  margin-top: 15px;
}
