@import (reference) './railsPalette.less';

.datacenter-topology {
  each(@railsPalette, .(@color, @name, @index) {
    .server-gpu-interface.rail-@{index} {
      cursor: pointer;
      fill: white;
      stroke: darken(@color, 20%);
    }
  });
}
