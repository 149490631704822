@import (reference) '~theme/globals/site.variables';

.iba-bullet-chart {
  .iba-bullet-chart-value {
    fill: @black;

    rect {
      opacity: 0.6;
    }

    text {
      text-anchor: start;
      font-size: 12px;
      font-weight: bold;
      dominant-baseline: central;
      
      fill: @black;
      stroke: @white;

      paint-order: stroke;
      stroke-width: 2px;
      stroke-opacity: 1;
    }

    &.align-right {
      text {
        text-anchor: end;
        fill: @white;
        stroke: @black;
      }
    }
  }
}
