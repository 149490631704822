@import (reference) '~theme/globals/site.variables';

.event-timeline {
  circle {
    fill: @blueBackground;
    stroke: @blue;
    stroke-width: 1px;
    &:hover {
      fill: darken(@blueBackground, 20%);
      stroke: darken(@blue, 20%);
    }
  }

  line {
    stroke: @blue;
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }

  .visx-axis-bottom  line.visx-axis-line {
    opacity: 0;
  }
}
