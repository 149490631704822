@import (reference) '~theme/globals/site.variables';

body {
  background: @white !important;
}

button {
  font-family: @pageFont;
}

@media print {
  .no-print, [role=navigation], [role=complementary] {
    display: none !important;
  }
}

@media screen {
  .print-only {
    display: none !important;
  }
}

#app-root {
  width: 100%;
  height: 100%;
}

.aos-container {
  width: 100%;
  height: 100%;
  min-width: @minScreenWidth;
  display: flex;
  > .main-content-wrapper {
    flex: 1;
  }

  --sidebar-width: @sidebarCollapsedWidth;
  &:has(.main-sidebar.expanded) {
    --sidebar-width: @sidebarExpandedWidth;
  }
  .main-sidebar {
    position: relative !important;
  }
}

code {
  font-size: 12px;
}

.ui.table {
  tr.read-only {
    background-color: @solidInternalBorderColor !important;
  }
  tbody td .label {
    word-break: normal;
  }
  &.fixed {
    th, td {
      overflow: visible;
    }
  }
}

.ui.floating.label {
  .icon {
    margin: 0;
  }
  &.red {
    color: @white !important;
    .icon {
      color: @white !important;
    }
  }
}

div.field.inline {
  display: inline;
}

// common modifiers

.ui.multiple.dropdown > .ui.label .ui.label {
  // styles for color-coded labels inside multiple dropdown items
  padding: @2px @4px;
  font-size: 0.8rem;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.fixed-width-font {
  font-family: monospace;
  font-size: 12px !important;
}

.vertical-top-align {
  vertical-align: top !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.5);
}

.kv-label {
  display: inline-block;
  margin: 0;
  color: @textColor;
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
}

.kv-value {
  color: @darkTextColor;
  font-size: 1em;
  font-weight: 300;
}

.validation-error-item {
  color: @red;

  ul {
    padding: 0 0 0 1em;
    margin: 0;
  }
  li {
    margin-top: 0.5em;

    &:first-child {
      margin-top: 0;
    }
  }
}

.tooltip-icon,
sup[data-tooltip] { // icon tooltips
  color: @greyActive;
  cursor: help;
  // the following styles needed to support large tooltips
  &:after {
    width: 300px;
    word-wrap: normal;
    white-space: normal;
  }
}

.ui.selection.dropdown {
  min-width: 8em !important;
}

.ui.search.dropdown {
  div.text, div.item {
    word-break: break-all !important;
  }
}

.ui.action.input input[type="text"] {
  text-overflow: ellipsis;
}

.active-side-menu-item {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

.ui.menu .item.disabled {
  opacity: 0.5 !important;
}

.ui.vertical.menu .item .floating.label {
  top: 0.8em !important;
}

.ui.menu .actions-menu-item:not(:last-child) .link.item.disabled:after {
  position: absolute;
  content: '';
  top: 0%;
  right: 0;
  height: 100%;
  width: 1px;
  background: @grey3;
}

.supported-physical-menu-item {
    position: relative;
    .global-help-button {
      display: none;
      position: absolute;
      right: -4px;
      top: 0;
    }
    &:hover {
      .global-help-button {
        display: block;
      }
    }
}

// addition button styles

.dotted-underline-button {
  background-color: transparent;
  color: @textColor;
  text-align: left;
  display: inline-block;
  border: 0;
  padding: 0;
  margin-bottom: 1px;
  border-bottom: 1px dotted @textColor;
  cursor: pointer;
  &:hover {
    border-bottom-width: 2px;
    margin-bottom: 0;
  }
}

.dashed-add-button {
  display: block;
  width: 100%;
  height: 50px;
  color: @textColor;
  background: @white;
  border: 1px dashed @borderColor;
  border-radius: @borderRadius;
  position: relative;
  cursor: pointer;
  user-select: none;
  > div {
    text-align: center;
    width: 50%;
    top: 50%;
    left: 50%;
    margin: auto;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  &:hover, &:focus {
    background: @offWhite;
    color: @hoveredTextColor;
  }
  &:active {
    background: darken(@offWhite, 2);
    color: @hoveredTextColor;
  }
  &.disabled {
    opacity: @disabledOpacity;
    cursor: default;
  }
}

// dropzone common styles

.dropzone {
  border: 2px dashed @borderColor;
  border-radius: @defaultBorderRadius;
  outline: 0;
  &.drag-accept {
    border-color: @green;
  }
  &.drag-reject {
    border-color: @red;
  }
}

// right vertical secondary menu
.ui.menu.vertical.secondary.right {
  border: 1px solid @borderColor;
  border-radius: @defaultBorderRadius;
  padding: 13px 0;

  .item {
    padding: 18px 20px;
    &:active, &.active {
      border-right: none;
      border-left-style: solid;
      border-left-width: 2px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid lighten(@borderColor, 2%);
    }
  }
}

.nerd-knob .checkbox label {
  transform: scale(0.8);
}

.ui.tabular.menu > .disabled.item {
  pointer-events: none;
}

i.light-grey.icon {
  color: @lightGrey !important;
}

.no-wrap-label {
    white-space: nowrap;
}

.st-tooltip-image {
    display: block;
    background-repeat: no-repeat;
    background-size: 100%;

    &.external {
       width: 400px;
       height: 70px;
       background-image: url("./images/external-generic.jpg");
    }
    &.internal {
       width: 290px;
       height: 250px;
       background-image: url("./images/internal-generic.jpg");
    }
}

.date-time-input .inverted {
    &.bottom:before {
        box-shadow: -1px -1px 0 0 @borderColor !important;
        background: @offWhite !important;
    }
    &.top:before {
        background: @white !important;
        box-shadow: 1px 1px 0 0 @borderColor !important;
    }
}

.dev-page-card {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  &.documentation {
    background-image: url("./images/devpage/1_documentation.png");
  }
  &.openapi {
    background-image: url("./images/devpage/2_openapi.png");
  }
  &.joinus {
    background-image: url("./images/devpage/3_joinus.png");
  }
  &.graphdb {
    background-image: url("./images/devpage/4_graphdb.png");
  }
  &.graphql {
    background-image: url("./images/devpage/5_graphql.png");
  }
  &.terraform {
    background-image: url("./images/devpage/6_terraform.png");
  }
  &.jinja-reference {
    background-image: url("./images/devpage/7_jinja-reference.png");
  }
  &.sdt-reference {
    background-image: url("./images/devpage/8_sdt-reference.png");
  }
  &.webcons {
    background-image: url("./images/devpage/9_webcons.png");
  }
  &.sdk-docs {
    background-image: url("./images/devpage/10_apstra-sdk.png");
  }
  &.traffic-heat {
    background-image: url("./images/dashboard/trafficheat.png");
  }
  &.qba {
    background-image: url("./images/dashboard/qba.png");
  }
  &.iba {
    background-image: url("./images/dashboard/iba.png");
  }
  &.flow-data {
    background-image: url("./images/dashboard/flowdata.png");
  }
}
