@import (reference) '../variables.less';
@import (reference) '../../components/graphs/DatacenterTopology/drawables/railsPalette.less';

div.re-links-group.title {
  display: flex;
  align-items: center;

  & > * {
    &:nth-child(n + 3) {
      margin-left: 5px !important;
    }
  }

  & > i {
    flex: 0 0 10px;
  }

  b {
    flex: 1 1;
    overflow-x: hidden;
  }

  & > .ui.button.re-port {
    margin-top: 0;
  }

  & > .ui.tiny.button {
    flex: 0 0 20px;
    margin: 0;
  }
}

div.content.active.re-links-group-content {
  padding: 10px  !important;
  margin: 0 -5px 8px;
  border-radius: 5px;
  background-color: lighten(@grey, 57%);

  .field {
    .required-field();

    .ui.fluid.multiple.search.selection.tags.dropdown > a {
      height: inherit;
    }

    &.is-in-rail {
      padding: 10px 0;
      font-weight: bold;
    }

    &.rail-index {
      each(@railsPalette, {
        @zeroIndex: @index - 1;
        .rail@{zeroIndex} {
          background-color: @value !important;
        }
      });
    }

    &.checkboxes {
      & > label {
        display: block;
        margin-bottom: 6px;
      }

      *:nth-child(n + 3) {
        margin-left: 10px;
      }
    }

    &.lag-mode {
      div {
        white-space: nowrap;
        vertical-align: top;

        div.ui.radio.checkbox {
          &:nth-child(n + 2) {
            margin-left: 10px;
          }

          label {
            white-space: nowrap;

            i.info.circle {
              font-size: 0.8rem;
              color: @blue;
              position: relative;
              left: 2px;
              bottom: 4px;
            }
          }
        }
      }
    }
  }
}
