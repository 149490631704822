@import (reference) '~theme/globals/site.variables';
@import (reference) './railsPalette.less';

.datacenter-topology {
  each(@railsPalette, .(@color, @name, @index) {
    .rail-link.rail-@{index} {
      cursor: pointer;
      .rail-link-curve {
        fill: none;
        stroke: fade(@color, 70%);
        stroke-width: @1px;
      }
      .rail-link-hover-area {
        fill: none;
        stroke: transparent;
        stroke-width: @5px;
      }
      &:hover {
        .rail-link-curve {
          stroke: @color;
          stroke-width: @2px;
        }
      }
    }
  });
}
