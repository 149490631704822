@import (reference) '~theme/globals/site.variables';

.histogram-chart.graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > svg {
    width: 100%;
  }
  &.expandable {
    &:hover {
      cursor: pointer;
    }
  }

  g.bars {
    & > rect {
      fill: @blue;
      &:hover {
        fill: darken(@blue, 10%);
      }
    }
  }
}

.histogram-chart-layout {
  shape-rendering: crispEdges;
  .numeric-chart-layout-time-indicator {
    stroke-width: 2px;
    stroke-dasharray: 2px;
    stroke: @black;
  }
  .numeric-chart-layout-zero-line {
    stroke: @grey;
    stroke-width: 1px;
    opacity: 0.5;
    pointer-events: none;
  }
}

.histogram-no-data {
  text-align: center;
  font-style: italic;
}
