@import (reference) 'apstra-ui-common/src/brandPalette.less';
@import (reference) '~theme/globals/site.variables';
@import (reference) '../../../styles/variables';

.bar-group-chart.graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.expandable {
    &:hover {
      cursor: pointer;
    }
  }

  &.linear {
    &:not(.stacked) {
      .bar-group-chart-layout {
        .bar {
          opacity: 0.8;
          stroke: fade(@white, 50%);
          stroke-width: 1px;
        }
      }
    }
  }
  
  .bar-group-chart-layout {
    each(@brandPalette, .(@color, @name) {
      .bar.@{name} {
        fill: @color;
      }
      .visx-group:hover {
        & > .bar.@{name} {
          fill: darken(@color, 10%);
        }
        & > .circle {
          display: initial;
        }
      }
      .circle.@{name} {
        stroke: @white;
        stroke-width: 2px;
        fill: @color;
        display: none;
      }
    });
  }
}

.bar-group-popup {
  display: flex;
  align-items: center;
  .legend {
    border: 1px solid @borderColor;
    width: @10px;
    height: @10px;
    margin-right: @5px;
    each(@brandPalette, .(@color, @name) {
      &.@{name} {
        background-color: @color;
      }
    });
  }
}
