@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

.ui.table.environment-expectations {
  thead tr:first-child th:first-child {
    color: @headerColor;
    font-weight: @headerFontWeight;
  }
  tbody td {
    font-weight: @normal !important;
  }
  td, th {
    border-left: none !important;
    background: none !important;
  }
}
