@import (reference) '~theme/globals/site.variables';
@import '~apstra-ui-common/styles/colors.less';
@import (reference) '../../styles/variables.less';

@dropdownTitleHeight: @40px;
@openDropdownMaxHeight: @60px * 5;
@dropdownWidth: @60px * 3;
@controlsHeight: @40px;

.ui.accordion.menu.dropdown-with-checkboxes {
  position: relative;
  z-index: 9;
  width: @dropdownWidth;
  box-shadow: none;
  margin: 0;
  &.open {
    border: @1px solid @teal;
    border-bottom-color: @white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:not(.unlimited-height) {
    .content.active {
      max-height: @openDropdownMaxHeight;
    }
    &.with-controls {
      .content.active {
        .ui.selection.list {
          max-height: @openDropdownMaxHeight - @controlsHeight - @suirPadding;
          position: relative;
          overflow-y: scroll;
          margin-bottom: @controlsHeight;
        }
        .actions-section {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: @white;
        }
      }
    }
  }
  .title {
    height: @dropdownTitleHeight;
    padding: @10px @10px @10px @15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    i {
      margin: 0;
    }
  }
  &:not(.with-controls) {
    .content.active {
      overflow-y: auto;
    }
  }
  .content.active {
    position: absolute;
    padding: @5px @10px @10px @5px;
    background-color: @white;
    width: @dropdownWidth;
    border: @1px solid @teal;
    left: -@1px;
    border-top: none;
    border-bottom-left-radius: @5px;
    border-bottom-right-radius: @5px;
    .ui.selection.list {
      .item {
        padding: 0;
        margin-bottom: @3px;
        > .ui.checkbox {
          display: block;
          padding: @5px @10px;
          &.disabled > label {
            color: @grey7;
          }
          label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
    .actions-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: @controlsHeight;
      border-bottom-left-radius: @5px;
      border-bottom-right-radius: @5px;
      padding: @5px @10px @10px @10px;
      font-size: @11px;
      width: 100%;
      > div {
        flex: 1;
      }
      .selection-control {
        display: flex;
        flex-direction: column;
        > button {
          cursor: pointer;
          padding: 0;
          border: none;
          background-color: transparent;
          &:not(:last-child) {
            margin-bottom: @5px;
          }
          > span {
            color: @grey7;
            text-decoration: underline;
            text-decoration-style: dashed;
            &:hover, &:focus {
              color: @grey9;
            }
          }
        }
      }
      div.primary {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button.ui.primary.mini.compact.button {
          margin: 0!important;
        }
      }
    }
  }
}

each(range(1%, 100%, 1), {
  .ui.accordion.menu.dropdown-with-checkboxes.width-scale-@{index} {
    width: @dropdownWidth + @dropdownWidth * @value / 100;
    .content.active {
      width: @dropdownWidth + @dropdownWidth * @value / 100;
    }
  }
});
