@import (reference) '~theme/globals/site.variables';

.ui {
  .dropdown {
    .menu {
      .collector-mapping-item {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
      }
    }
  }
}

.ddt-device-item {
  h4.header.styled {
    font-weight: bold;
    position: relative;

    i {
      color: @lightTextColor;
      text-transform: none;
      font-weight: normal;
      display: inline-block;
      margin-left: 1em;
      font-size: 1rem;
    }
  }
}
