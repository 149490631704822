@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.discrete-state-timeline {
  font-size: 12px;

  g {
    &.axes {
      line.visx-axis-line {
        opacity: 0.2;
      }
    }

    rect {
      fill: @white;
      stroke: @black;
      stroke-width: 1px;
      shape-rendering: crispEdges;
    }

    text {
      dominant-baseline: central;
      text-anchor: middle;
      pointer-events: none;
    }

    each(@graph-colors, {
      &.graph-color-@{value} {
        @backgroundColorVariable: "@{value}Background";
        @lightColor: @@backgroundColorVariable;
        @darkColor: @@value;
        rect {
          stroke: @darkColor;
          fill: @lightColor;
        }
        text {
          fill: @darkColor;
        }
        &:hover {
          rect {
            stroke: darken(@darkColor, 10%);
            fill: darken(@lightColor, 10%);
          }
          text {
            fill: darken(@darkColor, 10%);
          }
        }
      }
    });
  }
}
