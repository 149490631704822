@import "../variables.less";

.ui.button.re-port {
  display: inline-flex;

  width: @portSize;
  height: @portSize;
  border: solid 2px @white;
  align-items: center;
  justify-content: center;
  margin: 14px 1px 0 1px;
  padding: 0;
  color: @white;
  font-size: 12px;

  &:before {
    display: block;
    position: absolute;
    margin-top: calc(-@portSize - 18px);
    color: fade(@black, 50%);
    font-size: 7pt !important;
  }

  &:not(.interactive) {
    cursor: default;
  }

  &.selected {
    // border-color: lighten(@grey, 40%);
    outline: solid 2px darken(@teal, 20%);
  }

  &.empty {
    background:
      linear-gradient(
        to top left,
        @transparentWhite 0%,
        @transparentWhite calc(50% - 0.8px),
        @red 50%,
        @transparentWhite calc(50% + 0.8px),
        @transparentWhite 100%
      ),
      linear-gradient(
        to top right,
        @transparentWhite 0%,
        @transparentWhite calc(50% - 0.8px),
        @red 50%,
        @transparentWhite calc(50% + 0.8px),
        @transparentWhite 100%
      );
  }

  &.port-na { /* stylelint-disable-line selector-class-pattern */
    background-color: lighten(@grey, 50%);
  }

  &.port-1G { /* stylelint-disable-line selector-class-pattern */
    background-color: @orange;

    &:before {
      content: '1G';
    }
  }

  &.port-10G { /* stylelint-disable-line selector-class-pattern */
    background-color: @blue;

    &:before {
      content: '10G';
    }
  }

  &.port-25G { /* stylelint-disable-line selector-class-pattern */
    background-color: @yellow;

    &:before {
      content: '25G';
    }
  }

  &.port-40G { /* stylelint-disable-line selector-class-pattern */
    background-color: @violet;

    &:before {
      content: '40G';
    }
  }

  &.port-100G { /* stylelint-disable-line selector-class-pattern */
    background-color: @purple;

    &:before {
      content: '100G';
    }
  }

  &.port-200G { /* stylelint-disable-line selector-class-pattern */
    background-color: @pink;

    &:before {
      content: '200G';
    }
  }

  &.port-400G { /* stylelint-disable-line selector-class-pattern */
    background-color: @olive;

    &:before {
      content: '400G';
    }
  }

  &.port-800G { /* stylelint-disable-line selector-class-pattern */
    background-color: @red;

    &:before {
      content: '800G';
    }
  }
}
