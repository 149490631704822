@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.state-line-chart.numeric-chart-layout {
  .state-line-chart-line {
    shape-rendering: auto;
    pointer-events: none;
    stroke-width: @2px;
  }
  .state-line-chart-dashed-line {
    stroke-dasharray: @3px @6px;
    stroke-width: @2px;
    pointer-events: none;
  }
  each(@graph-colors, {
    @colorBackground: "@{value}Background";
    .graph-color-@{value} {
      &.state-line-chart-line, &.state-line-chart-dashed-line {
        stroke: @@value;
      }
      &.state-line-chart-value-circle {
        fill: @white;
        stroke: @@value;
        stroke-width: @2px;
        &.selected {
          fill: darken(@@colorBackground, 25%);
          stroke: darken(@@colorBackground, 50%);
        }
        &.hidden {
          display: none;
        }
        &.standalone {
          stroke: lighten(@@value, 30%);
        }
      }
    }
  });
}
