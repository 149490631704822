.probe-graph__control-panel { // stylelint-disable-line selector-class-pattern
  position: sticky;
  top: 14px;
  left: 0;
  right: 0;
  text-align: right;
}

.probe-graph__scrollable-container { // stylelint-disable-line selector-class-pattern
  overflow: auto;
}
