@import (reference) '~theme/globals/site.variables';

@interfaceBackgroundColor: lighten(@grey, 5);
@selectedColor: #0096a4;
@hoveredColor: #FFB71B;
@downColor: @lightGrey;

.node-neighbors-intent-graph {
  .link {
    //state
    &.state-up {
      stroke-dasharray: none;
    }
    //statuses
    &.status-success {
      stroke: @green;
    }
    &.status-error {
      stroke: @red;
    }
    &.status-warning {
      stroke: @yellow;
    }
    &.status-info {
      stroke: @blue;
    }
    &.status-not-available {
      stroke: @grey;
    }
    &.selected {
      stroke: @selectedColor;
    }
    &.hovered {
      stroke: @hoveredColor;
    }
  }

  .interface {
    &.status-success {
      background-color: @positiveColor;
    }
    &.status-error {
      background-color: @negativeColor;
    }
    &.status-warning {
      background-color: @yellow;
    }
    &.status-info {
      background-color: @infoColor;
    }
    &.status-not-available {
      background-color: @interfaceBackgroundColor;
    }
    &.down {
      background-color: @downColor;
    }
    &.highlighted {
      background-color: @selectedColor;
    }
    &.hovered {
      background-color: @hoveredColor;
    }
  }

  .rail {
    .link {
      &.hovered {
        stroke: @hoveredColor;
      }
      &.selected {
        stroke: darken(@selectedColor, 8%);
      }
    }
  }
}
