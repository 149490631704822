@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/form.variables';

.field {
  &.string-filter {
    .ui.dropdown {
      .divider.text {
        width: 16px;
      }
      .description {
        margin-left: 24px !important;
      }
    }
  }

  &.number-filter {
    .fields {
      margin: 0 -(@gutterWidth / 4);

      > .wide.field {
        padding-left: @gutterWidth / 4;
        padding-right: @gutterWidth / 4;
      }

      > .field.no-input-field {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
