@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.gauge-container {
  position: relative;
  margin: auto;
  width: fit-content;

  .gauge {
    display: block;
    margin: 0 auto;

    &.clickable {
      cursor: pointer;
    }

    .gauge-label {
      font-size: 10px;
    }

    .gauge-pointer {
      stroke-width: 1px;
    }

    .gauge-arc {
      &.hidden {
        display: none;
      }
      &.graph-color-base {
        fill: @teal;
      }
      &.graph-color-value {
        fill: @red;
      }
    }
    .gauge-background-arc {
      fill: @greyBackground;
    }
  }

  .gauge-info-tooltip {
    position: absolute;
    top: -@5px;
    right: 0;
    cursor: help;
    font-size: @relativeTiny;
  }

  .markup * {
    fill: none;
    stroke: @teal;
  }
}
