.neighbors-view {
  display: flex;
}

.neighbors-view-filter {
  margin: 0 auto 0.8em;
  display: flex;
  gap: 1rem;
  justify-content: right;

  & > * {
    flex: 1 1;
    align-content: center;

    &:last-child {
      text-align: right;
      flex-grow: 0;
      white-space: nowrap;
    }
  }
}
