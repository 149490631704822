@import (reference) '~theme/globals/site.variables';
@import (reference) '~theme/collections/table.variables';

.graph-container.single-horizontal-bar {
  padding: 0 @cellHorizontalPadding;
  height: 100%;
  min-width: 100px !important;
  > .bar-value {
    text-align: center;
  }
  > .bar-container {
    position: relative;
    height: 1em;
    > div {
      position: absolute;
      height: 100%;
      &.horizontal-bar {
        background: @blueBackground;
        border-right: 2px solid lighten(@blue, 30%);

        &.negative {
          background: @purpleBackground;
          border-right: none;
          border-left: 2px solid lighten(@purple, 30%);
        }
      }
    }
  }
}
