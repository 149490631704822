@import '../../../variables.less';

.time-data, .anomaly-description-data {
  > div > em {
    display: inline-block;
    padding-bottom: @5px;
  }
}

div.ui.popup.transition.visible.graph-popup {
  .content {
    max-width: 80vh;
    overflow: hidden;
    .anomaly-history-data {
      overflow: hidden;
      > div:not(:last-child) {
        margin-bottom: @15px;
      }
      .time-data > div:not(:last-child) {
        margin-bottom: @10px;
      }
      .anomaly-description-data {
        display: flex;
        flex-wrap: wrap;
        gap: @15px;
      }
    }
  }
}

div.ui.page.modals.dimmer.transition.visible.active {
  div.ui.modal.transition.visible.active.expanded-anomalies-history {
    top: @suirPadding * 3;
    max-height: calc(100vh - (6 * @suirPadding));
    .content {
      max-height: calc(100vh - 10em);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      .selection-controls {
        position: absolute;
        top: @suirPadding;
        right: @suirPadding;
        display: flex;
        align-items: center;
        .out-of-range-label {
          margin-right: @10px;
          display: flex;
          align-items: center;
          &.hidden {
            display: none;
          }
          i.icon.question.circle {
            margin: 0 0 0 @5px;
          }
        }
        .reset-selection-button {
          display: flex;
          align-items: center;
          i.icon.small.undo:before {
            margin-left: @5px;
          }
          &.hidden {
            display: none;
          }
        }
      }
      .timeline.graph-container {
        padding-top: @20px;
      }
      .anomaly-history-data {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: @20px;
        margin-top: @40px;
        .time-data {
          display: flex;
          gap: @15px;
          > div {
            flex: 1;
          }
        }
        .anomaly-description-data {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: @20px;
        }
      }
    }
  }
}