@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.iba-anomaly-history-graph-legend {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  line-height: 1.5em;
  margin: 0.5rem 0.5rem 0 0.9rem;

  .section {
    flex-basis: 50%;
    flex-grow: 0;
    padding-right: 5px;
  }

  .section-row {
    white-space: normal;
  }

  .section-title {
    font-weight: bold;
    font-size: 1.1em;
  }

  .section-row-title {
    color: @grey;
    font-weight: bold;
  }

  each(@graph-colors, {
    .graph-color-@{value} {
      @backgroundColorVariable: "@{value}Background";
      border: 1px solid @@value;
      background-color: @@backgroundColorVariable;
      padding: 0 4px;
    }
  });
}
