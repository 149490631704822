@import (reference) '~theme/globals/site.variables';
@import (reference) 'apstra-ui-common/src/graphColors.less';

.chart-hover-handler-circle {
  pointer-events: none;
  fill: @white;
  stroke-width: 2px;
  &.hidden {
    display: none;
  }
}

each(@graph-colors, {
  @colorBackground: "@{value}Background";
  .chart-hover-handler-line.graph-color-@{value} {
    stroke: @@value;
  }
  .chart-hover-handler-circle.graph-color-@{value} {
    stroke: darken(@@value, 10%);
    fill: lighten(@@value, 10%);
    &.selected {
      fill: none;
    }
  }
});
